import React, { FC } from 'react';
import { Icon } from '@digando/react-component-library';
import { isFeatureFlagEnabled } from '../../../lib/environment';
import styles from './social-media-links.module.scss';

export const PageFooterSocialMediaLinks: FC = () => {
  if (false === isFeatureFlagEnabled('isSocialMediaEnabled')) {
    return null;
  }

  return (
    <div className={styles.socialMediaLinks}>
      <a className={styles.socialMediaItem} href={'https://www.facebook.com/digando.ready2rent'} target={'_blank'} rel='noopener'>
        <Icon size={'32px'} icon={'facebook'} color={`var(--white)`} /> <span className={styles.srOnly}>Facebook</span>
      </a>
      <a className={styles.socialMediaItem} href={'https://www.instagram.com/digando_com/'} target={'_blank'} rel='noopener'>
        <Icon size={'32px'} icon={'insta'} color={`var(--white)`} /> <span className={styles.srOnly}>Instagram</span>
      </a>
      <a className={styles.socialMediaItem} href={'https://www.linkedin.com/company/digando/'} target={'_blank'} rel='noopener'>
        <Icon size={'32px'} icon={'linkedin'} color={`var(--white)`} /> <span className={styles.srOnly}>LinkedIn</span>
      </a>
    </div>
  );
};
