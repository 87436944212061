import { useGetNavigation } from '../../../@types/codegen/page';
import styled from '@emotion/styled';
import { FontSecondarySemiBold, mediaQueries, spacingBottom } from '@digando/react-component-library';
import React, { FC } from 'react';
import { ComponentHelperNavigationLink } from '../../../@types/codegen/graphql';
import { UiNavigationLink } from '../../../lib/strapi/ui-navigation-link';

type FooterNavigationItemProps = {
  link: ComponentHelperNavigationLink | null;
};

const FooterNavigationItem: FC<FooterNavigationItemProps> = ({ link }) => {
  return (
    <LinkItem key={link?.id}>
      <UiNavigationLink link={link} />
    </LinkItem>
  );
};

export const FooterNavigation = () => {
  const { data } = useGetNavigation(() => {
    return {
      variables: {},
      fetchPolicy: 'cache-only',
    };
  });

  const navigationData = data?.navigation?.data?.attributes;
  const footerNavigationLeft = navigationData?.footerLeft ?? [];
  const footerNavigationRight = navigationData?.footerRight ?? [];

  return (
    <>
      <NavigationContainer key={`navigation-group-left`}>
        {footerNavigationLeft.map(link => (<FooterNavigationItem key={`item-${link?.id}`} link={link} />))}
      </NavigationContainer>

      <NavigationContainer key={`navigation-group-right`}>
        {footerNavigationRight.map(link => (<FooterNavigationItem key={`item-${link?.id}`} link={link} />))}
      </NavigationContainer>
    </>
  );
};

const LinkItem = styled.div`
    a {
        display: block;
        ${FontSecondarySemiBold};
        ${spacingBottom(2)};
        font-size: var(--font-size-headline-h3);
        line-height: 24px;
        color: var(--page-footer-font-color);
        text-decoration: none;
    }
`;

const NavigationContainer = styled.div`
  grid-column: auto / span 6;

  div:last-of-type a {
    ${spacingBottom(0)};
  }

  @media ${mediaQueries.laptop} {
    order: 0;
    grid-column: 7 / span 3;

    :nth-of-type(2n+1) {
      grid-column: auto / span 3;
    }
  }

  @media ${mediaQueries.desktop} {
    order: 1;
  }
`;
