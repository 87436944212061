import Link from 'next/link';
import { usePageUrl } from './ssr-generate-page-url';
import React, { FC } from 'react';
import { ComponentHelperNavigationLink } from '../../@types/codegen/graphql';

type UiNavigationLinkProps = {
  link: ComponentHelperNavigationLink | null;
}

export const UiNavigationLink: FC<UiNavigationLinkProps> = ({ link }) => {
  const { generatePageUrl } = usePageUrl();

  if (!link) {
    return null;
  }

  const slug= link?.navigationLinkPage?.slug ?? '';
  const contentType= link?.navigationLinkPage?.contentType ?? '';
  const text = link?.navigationLinkPage?.text ?? '';

  if ('external' === contentType) {
    return (
      <a href={slug} target={'_blank'} rel={'noreferrer'}>{text}</a>
    );
  }

  return (
    <Link href={generatePageUrl(contentType, slug)}>{text}</Link>
  );
};
