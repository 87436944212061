import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ContentContainerMax } from '../../../layout/container';
import {
  spacingBottom,
  spacingTop,
  spacingTopBottom,
  spacingCalculator,
} from '@digando/react-component-library';
import Link from 'next/link';
import { mediaQueries } from '../../../styles/media-queries';
import DigandoLogo from '../../../svg/PlatformLogo';
import { Newsletter } from '../../newsletter/newsletter';
import { isFeatureFlagEnabled } from '../../../lib/environment';
import { FooterNavigation } from './footer-navigation';
import { PageFooterSocialMediaLinks } from './social-media-link';

const PageFooterBig: FC = () => {
  return (
    <PageFooterContainer>
      <TopBar />
      <PageFooterInner>
        <ContainerLeft>

          <LogoSocialWrapper>
            <DigandoLogoWrapper>
              <Link href={'/'} passHref prefetch={false} aria-label='digando.com'>
                <DigandoLogo />
              </Link>
            </DigandoLogoWrapper>
            {isFeatureFlagEnabled('isSocialMediaEnabled') && (
              <PageFooterSocialMediaLinks />
            )}
          </LogoSocialWrapper>

          {isFeatureFlagEnabled('isNewsletterEnabled') && (
            <NewsletterContainer>
              <Newsletter identifier={'page-footer'} />
            </NewsletterContainer>
          )}
        </ContainerLeft>

        <FooterNavigation />

      </PageFooterInner>
    </PageFooterContainer>
  );
};

const PageFooterContainer = styled.footer`
  width: 100%;
  background-color: var(--page-footer-background-color);
  color:  var(--page-footer-font-color);

  @media print {
    display: none;
  }
`;

const PageFooterInner = styled(ContentContainerMax)`
  ${spacingTop(7)}
  ${spacingBottom(6)}
`;

const TopBar = styled.div`
  width: 100%;
  height: var(--spacing-steps);
  background-color: var(--primary-color);
`;

const ContainerLeft = styled.div`
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    order: 2;
  }

  @media ${mediaQueries.laptop} {
    grid-column: auto / span 5;
    order: 0;
  }
`;

const LogoSocialWrapper = styled.div`

  @media ${mediaQueries.tablet} {
    ${spacingTopBottom(5)};
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: ${spacingCalculator((5))};
  }

  @media ${mediaQueries.laptop} {
    ${spacingTop(0)};
  }
`;

const DigandoLogoWrapper = styled.div`
  ${spacingBottom(7)};

  @media ${mediaQueries.tablet} {
    ${spacingBottom(0)};
    grid-column: auto / span 3;
  }

  @media ${mediaQueries.laptop} {
    grid-column: auto / span 4;
  }

  img {
    width: auto !important;
    height: 30px;
    vertical-align: bottom;

    @media ${mediaQueries.tablet} {
      width: 100%;
      padding: 0;
    }
  }
`;

const NewsletterContainer = styled.div`
  ${spacingBottom(10)};

  @media ${mediaQueries.laptop} {
    ${spacingBottom(0)};
  }
`;

export default React.memo(PageFooterBig, () => true);
